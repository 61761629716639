@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', 'Segoe UI', 'Roboto', 'Oxygen',
     'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* thin scrollbar */
.scrollbar-thin::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: #c8d0fe; /* Add a background color for the scrollbar track */
  border-radius: 10px; /* Ensure track has rounded corners */
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #3758ff; /* Set a visible color for the scrollbar thumb */
  border-radius: 10px; /* Ensure the thumb has rounded corners */
  /* border: 2px solid #3758ff;  */
}

/* Gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient-x {
  background-size: 300% 300%;
  animation: gradientAnimation 6s ease-in-out infinite;
}

.toastifybody {
  font-size: x-small;

}
.carousel-container .react-multiple-carousel__arrow {
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black */
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.carousel-container .react-multiple-carousel__arrow--left {
  left: -0%;
  margin-left: 10px; 
}

.carousel-container .react-multiple-carousel__arrow--right {
  right: -0px; 
  margin-right: 10px; 
}

.carousel-container .react-multiple-carousel__arrow:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
}
